import { downloadBlobFile } from './download-Blob'

/**
 * txt文件下载
 * @param {*} url 文件地址
 * @param {*} fileName 文件名
 */
export const txtFileDownload = (url:any) => {
  // 若没有文件名，则由地址中取
  const fileUrl = url.split('?')[0] // 问号前地址
  const fileNameList = fileUrl && fileUrl.split('/') // 取文件名
  const fileName = fileNameList[fileNameList.length - 1]
  downloadBlobFile(url, fileName)
}
