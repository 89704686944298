import axios from 'axios'

const openDownloadDialog = (url: any, fileName: string) => {
  if (typeof url === 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url) // 创建blob地址
  }
  const aLink = document.createElement('a')
  aLink.href = url
  aLink.download = fileName
  aLink.click()
}
/**
 * 下载文件-Blob方法
 * @param {*} url 文件地址
 * @param {*} fileName 文件名
 * @param {*} type 文件类型
 */
export const downloadBlobFile = (url: any, fileName:string) => {
  axios({
    method: 'get',
    url: url,
    responseType: 'blob'
  })
    .then(({ data }) => {
      openDownloadDialog(data, fileName)
    })
}
